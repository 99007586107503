import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { exportIncomeTaxForms, getIncomeTaxForms } from "api/services/automation/income-tax";
import SearchContainer from "components/SearchContainer";
import Table from "components/Table";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ResType } from "types";
import { getAutFinancialYears } from "utils/getFinancialYears";
import { StyledSpan } from "views/atom-pro/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  calculateAssessmentYear,
  clientCategories,
  incomeTaxForms,
  incomeTaxFormsFilingTypes,
} from "./constants";
import CategoryIcon from "@mui/icons-material/Category";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import useTitle from "hooks/useTitle";
import { getOrganizationPreference } from "api/services/common";
import { snack } from "components/toast";
import { getCurrentFormattedDateTime } from "utils";
import { handleError } from "utils/handleError";

function generateAssessmentYears() {
  const startYear = 2007;
  const currentYear = new Date().getFullYear();
  const assessmentYears = {};
  for (let year = startYear; year <= currentYear; year++) {
    const assessmentYearStart = year.toString();
    const assessmentYearEnd = (year + 1).toString().slice(2);
    assessmentYears[assessmentYearStart.toString()] =
      assessmentYearStart.toString() + "-" + assessmentYearEnd;
  }

  return assessmentYears;
}

const IncomeTaxForms = () => {
  useTitle("ATOM Pro | Forms");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [filters, setFilters] = useState<any>({
    formDesc: null,
    filingType: null,
    assessmentYear: null,
    financialYear: null,
    clientCategory: null
  });
  const forms = incomeTaxForms;
  const assessmentYear = getAutFinancialYears();
  const financialYear = getAutFinancialYears();
  const formsFilingTypes = incomeTaxFormsFilingTypes;
  
  const location = useLocation();
  const state: any = location.state;

  const { data: incomeTaxAccess, isLoading: isLoadingIncomeTaxAccess }: ResType = useQuery(
    [
      "Income Tax Access Check"
    ],
    getOrganizationPreference, {
    onSuccess: (res: any) => {
      if (res?.data?.automationConfig?.incomeTax === "YES") {

      } else {
        snack.error("Please subscribe Atom Pro Income tax")
        navigate("/atom-pro/incomeTaxLimit")
      }
    }
  }
  );


  const { data, isLoading }: ResType = useQuery(
    [
      "incomeTaxForms",
      {
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        ...filters,
      },
    ],
    getIncomeTaxForms,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );

  const getCategoryBackgroundColor = (category) => {
    switch (category) {
      case "individual":
        return "#FCF4C5";
      case "huf":
        return "#E0F7FA";
      case "partnership_firm":
        return "#FFE0B2";
      case "llp":
        return "#D1C4E9";
      case "company":
        return "#C8E6C9";
      case "aop":
        return "#FFCCBC";
      case "boi":
        return "#C5CAE9";
      case "trust":
        return "#F8BBD0";
      case "government":
        return "#B2DFDB";
      case "local_authority":
        return "#FFD54F";
      case "artificial_judicial_person":
        return "#F0F4C3";
      case "corporations":
        return "#D7CCC8";
      default:
        return "#FFFFFF";
    }
  };

  let columns = [
    {
      key: "",
      title: (
        <Box display="flex" alignItems="center">
          <CategoryIcon style={{ width: "20px", marginRight: "5px" }} />
          Category
        </Box>
      ),
      render: (v) => {
        const categoryLabel =
          clientCategories.find((category) => category.value === v?.client?.category)?.label || "";
        const backgroundColor = getCategoryBackgroundColor(v?.client?.category);

        return (
          <Typography
          // variant="subtitle2"
          // sx={{
          //   backgroundColor: backgroundColor,
          //   display: "inline-block",
          //   padding: "0px 5px",
          //   border: "1px solid #EDE7FB",
          //   borderRadius: "4px",
          //   color: "#222222",
          //   fontSize: "12px",
          // }}
          >
            {categoryLabel}
          </Typography>
        );
      },
    },
    {
      key: "",
      title: (
        <Box display="flex" alignItems="center">
          <PersonOutlineOutlinedIcon style={{ width: "20px", marginRight: "5px" }} /> Name | PAN
        </Box>
      ),
      render: (row) => (
        <Typography
        // sx={{
        //   color: "#222222",
        // }}
        // variant="body2"
        >
          {row?.client?.displayName} (
          <span
          // style={{
          //   backgroundColor: "#ff9595",
          //   display: "inline-block",
          //   padding: "0px 5px",
          //   border: "1px solid #EDE7FB",
          //   borderRadius: "4px",
          //   color: "#222222",
          //   fontSize: "12px",
          // }}
          >
            {row?.autClientCredentials?.panNumber}
          </span>
          )
        </Typography>
      ),
    },
    {
      key: "id",
      title: "FY / AY",
      render: (row: any) => {
        return (
          <Box
          // color="#ffffff"
          >
            {row?.refYear ? (
              row?.refYearType === "AY" ? (
                <Typography> AY {calculateAssessmentYear(row?.refYear)}</Typography>
                // <StyledSpan bgColor="#47d1d1">
                //   AY {calculateAssessmentYear(row?.refYear)}
                // </StyledSpan>
              ) : (
                <Typography> FY {calculateAssessmentYear(row?.refYear)}</Typography>
                // <StyledSpan bgColor="#47d1af">
                //   FY {calculateAssessmentYear(row?.refYear)}
                // </StyledSpan>
              )
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
    {
      key: "formDesc",
      title: "Form Name",
    },
    {
      key: "ackNum",
      title: "Acknowledgement #",
    },
    {
      key: "ackDt",
      title: "Filing Date",
    },
    {
      key: "filingTypeCd",
      title: "Filing Type",
      render: (row: any) => {
        return (
          <Box>
            {row?.filingTypeCd === "Original" ? (
              <StyledSpan bgColor="#f2e6ff">{row?.filingTypeCd}</StyledSpan>
            ) : (
              <StyledSpan bgColor="#b3ecff">{row?.filingTypeCd}</StyledSpan>
            )}
          </Box>
        );
      },
    },
  ];

  const handleRowClick = (v: any) => {
    navigate(`/atom-pro/income-tax/forms/${v?.id}`);
  };

  const handleFilingTypeChange = (e) => {
    setFilters({ ...filters, filingType: e.target.value });
    setPage(0);
  };

  const handleformDescChange = (e) => {
    setFilters({ ...filters, formDesc: e.target.value });
    setPage(0);
  };

  const handleAssessmentYearChange = (e) => {
    setFilters({ ...filters, assessmentYear: e.target.value });
    setPage(0);
  };

  const handleFinancialYearChange = (e) => {
    setFilters({ ...filters, financialYear: e.target.value });
    setPage(0);
  };

  const onClientCategoryChange = (e) => {
    setFilters({ ...filters, clientCategory: e.target.value });
    setPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e);
    setPage(0);
  }
  const { mutate: mutate1 } = useMutation(exportIncomeTaxForms, {
    onSuccess: (res: any) => {
        const arr = new Uint8Array(res.data?.data);
        const blob = new Blob([arr], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const file = window.URL.createObjectURL(blob);
        const formattedDateTime = getCurrentFormattedDateTime();

        let link = document.createElement("a");
        link.href = file;
        link.download = `Income Tax Forms Report ${formattedDateTime}.xlsx`;
        link.click();
        snack.success("Exported Successfully");
    },
    onError: (err: any) => {
        snack.error(err);
        snack.error(handleError(err));
    },
});
const handleExport = () => {
    mutate1({
        ...state,
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        ...filters,
    });
};
  return (
    <Box m={1}>
      <Box pr={2} pl={2} sx={{ display: "flex" }}>
        <Box display="flex">
          <Box ml={1}>
            <TextField
              label="Financial Year"
              value={filters.financialYear}
              sx={{ width: 100 }}
              onChange={handleFinancialYearChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {financialYear?.map((year, index) => (
                <MenuItem key={index} value={year.split("-")[0]}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={0.5}>
            <TextField
              label="Assessment Year"
              value={filters.assessmentYear}
              sx={{ width: 120 }}
              onChange={handleAssessmentYearChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {assessmentYear?.map((year, index) => (
                <MenuItem key={index} value={year.split("-")[0]}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Box ml={0.5}>
              <TextField
                name="clientCategory"
                value={filters.clientCategory}
                onChange={onClientCategoryChange}
                size="small"
                label="Category"
                select
                sx={{ width: "150px" }}
              >
                <MenuItem key={""} value={""}>
                  Select
                </MenuItem>
                {clientCategories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box ml={0.5}>
            <TextField
              label="Form Name"
              value={filters.formDesc}
              sx={{ width: 120 }}
              onChange={handleformDescChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {forms.map((key, index) => (
                <MenuItem key={index} value={key}>
                  {key}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={0.5} mr={1}>
            <TextField
              label="Filing Type"
              value={filters.filingType}
              sx={{ width: 100 }}
              onChange={handleFilingTypeChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {Object.keys(formsFilingTypes).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {formsFilingTypes[key]}
                </MenuItem>
              ))}
            </TextField>
          </Box>

        </Box>

        <SearchContainer
          minWidth="250px"
          value={search}
          debounced
          placeHolder="Search by  Name | PAN"
          onChange={handleSearch}
        />

        <Button
          onClick={() => {
            setFilters({})
            setSearch("")
          }}
          sx={{ marginLeft: '5px',marginRight:'5px' }}
          size="small"
          color="secondary"
          variant="outlined"
        >
          Clear Filters
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleExport}
                    startIcon={<FileDownloadOutlinedIcon />}
                >
                    Export
                </Button>
      </Box>

      <Box mt={1}>
        <Table
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data.result || []}
          columns={columns}
          loading={isLoading}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default IncomeTaxForms;
