import { Box, Typography } from "@mui/material"
import { getNewUpdateItem } from "api/services/automation/income-tax";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import UpdatesForms from "./forms";
import UpdatesReturns from "./return";
import UpdatesOutstandingDemand from "./outstandingDemand";
import UpdatesEproceedingFya from "./eProccedingFya";
import UpdatesEproceedingFyi from "./eProceedingFyi";
import AccessDenied from "../Access/accessDenide";
import Loader from "components/Loader";

const UpdatesFullVIew = () => {
    const params = useParams();
    const { data, isLoading }: ResType = useQuery(["get form data", params.id], getNewUpdateItem);
    if (isLoading) return <Loader />
    return (
        <>
            {!data?.data && (
                <AccessDenied />
            )}
            {data?.data && (
                <Box m={1} height="75vh" overflow="auto" mb={3}>
                    <Typography variant="h5" color="secondary" m={1}>Client Name: {data?.data?.client?.displayName}</Typography>
                    <UpdatesForms forms={data?.data?.forms} />
                    <UpdatesReturns returns={data?.data?.returns} />
                    <UpdatesOutstandingDemand demands={data?.data?.outstandingDemand} />
                    <UpdatesEproceedingFya eProceedingFya={data?.data?.eProceedingFya} />
                    <UpdatesEproceedingFyi eProceedingFyi={data?.data?.eProceedingFyi} />
                </Box>
            )}
        </>
    )
}

export default UpdatesFullVIew