import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { getTaskPreferences, updateTaskPreferences } from "api/services/invoice.preferencess";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleError } from "utils/handleError";

const TaskPreferences = () => {
    const queryClient = useQueryClient();
    const [task, setTask] = useState<any>(15);

    const { isLoading : queryLoading} = useQuery("task-preferenecs",getTaskPreferences,{
        onSuccess: (res: any) => {
            setTask(res?.data?.taskPreferences?.completedDays);
        }
    })

    const { data, mutate, isLoading } = useMutation(updateTaskPreferences, {
        onSuccess: () => {
          snack.success("Task Preferences Updated!");
          queryClient.invalidateQueries("task-preferenecs");
        },
        onError: (err: any) => {
          snack.error(handleError(err));
        },
    });  

    const handleUpdateTaskPrefernces = () => {
        mutate({data:{ taskPreferences: task }});
    }

    if(queryLoading) return <Loader />

    return <Box m={2}>
        <Typography variant="h5">
            Task Preferences
        </Typography>
        <Typography variant="body1" mt={2}>Upto How many Days Completed Tasks to be shown in Task Board ?</Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Preferences</InputLabel>
            <Select
                size="small"
                placeholder="preferences"
                labelId="Prefenences"
                id="prefernces"
                value={task || null}
                onChange={(value) => {
                    setTask(value.target.value)
                }}
                label="preferences"
            >
                <MenuItem key={"one"} value={1}>
                    One Day
                </MenuItem>
                <MenuItem key={"week"} value={7}>
                    One Week
                </MenuItem>
                <MenuItem key={"15"} value={15}>
                    15 Days
                </MenuItem>
            </Select>
        </FormControl>
        <Button variant="contained" disabled={isLoading} sx={{ mt: 2 }} color="secondary" onClick={handleUpdateTaskPrefernces}>Update</Button>
    </Box>
}

export default TaskPreferences