import { Box, Grid, IconButton, Typography } from "@mui/material"
import { getFormData } from "api/services/automation/income-tax";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import { styled } from "@mui/system";
import Loader from "components/Loader";
import AccessDenied from "./Access/accessDenide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface StyledSpanProps {
    bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
    backgroundColor: bgColor || "#ffffff",
    padding: "5px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "5px",
}));

const FormFullView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { data, isLoading }: ResType = useQuery(["get form data", params.id], getFormData);
    function calculatePluseYear(startYear) {
        const endYear = parseInt(startYear) + 1;
        return startYear + "-" + endYear.toString().slice(2);
    }

    const calculateMinusYear = (startYear) => {
        const endYear = parseInt(startYear);
        return parseInt(startYear) - 1 + "-" + endYear.toString().slice(2);
    }

    const calculateFyYear = (startYear) => {
        const endYear = parseInt(startYear) + 2;
        return parseInt(startYear) + 1 + "-" + endYear.toString().slice(2);
    }
    if (isLoading) return <Loader />
    return (

        <>
            {!data?.data && (
                <AccessDenied />
            )}

            {data?.data && (
                <Box m={1}>
                      <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="small" /> 
                  <Typography variant="subtitle2" ml={1}>Forms List</Typography>
                </IconButton>
                    <Typography variant="h5" color={"primary"} mb={1}>Form Details </Typography>
                    <Box height="70vh" overflow="auto">
                        <Grid container spacing={1} >
                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px"
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Client Name</Typography>
                                    <StyledSpan
                                    //  bgColor="#ffffcc"
                                    >
                                        {data?.data?.client?.autProfileDetails?.[0]?.name}
                                    </StyledSpan>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Financial Year</Typography>
                                    <StyledSpan
                                    // bgColor="#e6ffff"
                                    >
                                        {data?.data?.refYearType === "FY" ? `FY ${calculatePluseYear(data?.data?.refYear)}` : `FY ${calculateMinusYear(data?.data?.refYear)}`}
                                    </StyledSpan>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Assessment Year</Typography>
                                    <StyledSpan
                                    // bgColor="#e6ffff"
                                    >
                                        {data?.data?.refYearType === "AY" ? `AY ${calculatePluseYear(data?.data?.refYear)}` : `AY ${calculateFyYear(data?.data?.refYear)}`}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px"
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Form Name</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6ff"
                                    >
                                        {data?.data?.formDesc}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px"
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Form Header</Typography>
                                    <Typography fontWeight="500" fontSize="16px"> {data?.data?.formName}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Form Status</Typography>
                                    <StyledSpan
                                    // bgColor="#ffffcc"
                                    >
                                        {data?.data?.verStatus}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Filing Mode</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.fillingMode}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px"
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Filing Type</Typography>
                                    <StyledSpan
                                    // bgColor="#e6ffff"
                                    >
                                        {data?.data?.filingTypeCd}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Acknowledgement #</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.ackNum}
                                    </StyledSpan>
                                </Box>
                            </Grid>


                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Filing Date</Typography>
                                    <StyledSpan
                                    // bgColor="#ccfff5"
                                    >
                                        {data?.data?.ackDt}
                                    </StyledSpan>
                                </Box>
                            </Grid>




                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>CA Name</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.caName}
                                    </StyledSpan>
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>CA Membership #</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.caMembershipNo}
                                    </StyledSpan>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>UDIN </Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.udinNum}
                                    </StyledSpan>
                                </Box>
                            </Grid>



                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Submit User ID</Typography>
                                    <StyledSpan
                                    // bgColor="#ffe6e6"
                                    >
                                        {data?.data?.submitUserId}
                                    </StyledSpan>
                                </Box>
                            </Grid>




                            <Grid item xs={3}>
                                <Box
                                    style={{
                                        border: "1.5px solid #d1d1e0",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <Typography variant="h6" color="primary" mb={1}>Filed By</Typography>
                                    <StyledSpan
                                    // bgColor="#ccfff5"
                                    >
                                        {data?.data?.submitBy}
                                    </StyledSpan>
                                </Box>
                            </Grid>



                        </Grid>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default FormFullView