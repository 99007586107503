import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";
import DialogWrapper from "components/DialogWrapper";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { DialogProps } from "types";
import CachedIcon from "@mui/icons-material/Cached";

interface Props extends DialogProps {
  onSelectionChange: (selectedValues: string[]) => void;
  onSync: any
}

const options = [
  { label: "Profile", value: "P" },
  { label: "Forms", value: "F" },
  { label: "Returns", value: "R" },
  { label: "Returns With Attachments", value: "RWA" },
  { label: "Outstanding Demand", value: "OD" },
  { label: "e-Proceeding", value: "EP" },
];

function SelectModulePopUp(props: Props) {
  const { open, setOpen, onSelectionChange, onSync } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectAll) {
      const selectedArry = options.map((option) => option.value)
      setSelectedValues([...selectedArry]);
      onSelectionChange(selectedArry)
    } else {
      setSelectedValues([]);
    }
  }, [selectAll]);

  useEffect(() => {
    setSelectedValues([])
    setSelectAll(false)
  }, [open])

  const handleCheckboxChange = (value: string) => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
    onSelectionChange(newSelectedValues);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedValues.length > 0) {
      onSync();
      // setSelectedValues([])
    } else {
      snack.error("Please select atleast one module")
    }

  };

  const handleCancel = () => {
    setSelectedValues([])
    setOpen(false);
  };

  return (
    <DialogWrapper title="Select Income Tax Modules" open={open} setOpen={setOpen} >
      <form onSubmit={handleSubmit}>
        <Box mt={2}>

          <FormControlLabel
            control={
              <Switch
                checked={selectAll}
                onChange={() => setSelectAll(!selectAll)}
                color="primary"
              />
            }
            label="Select All"
          />
          <Box mb={2}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={selectedValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                }
                label={option.label}
              />
            ))}
          </Box>
          <Box>
            <Typography mt={1} fontSize={"10px"}>
              <span style={{ color: "red" }}>Disclaimer: </span>
              It is recommended that you refrain from logging in to the Income Tax
              portal on a second device while automation processes are running in
              the background. This will help to avoid any potential disruptions or errors
              that may occur due to conflicting sessions.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Button
              style={{ width: "100%", marginBottom: "10px" }}
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              startIcon={<CachedIcon />}
            >
              Start SYNC
            </Button>
            <Button
              onClick={handleCancel}
              style={{ width: "100%", marginRight: "10px" }}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </DialogWrapper>
  );
}

export default SelectModulePopUp;
