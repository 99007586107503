import { Box, Divider, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import atomByViderLogo from "assets/images/atomByViderLogo.png"
import { useContext, useState } from "react";
import GstDetails from "views/settings/organization/OrganizationProfile/GstDetails";
import PanDetails from "views/signup/PanDetails";
import ViderServer from "./viderserver";
import CustomSmtp from "./customsmtp";
import { useImmer } from "use-immer";
import BottomBar from "components/BottomBar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getOrganization, getSmtp, updateOrganization, updateSmtp } from "api/services/organization";
import { snack } from "components/toast";
import { ResType } from "types";
import { UserProfileContext } from "context/UserProfile";
import BlockIcon from "@mui/icons-material/Block";
import { useConfirm } from "context/ConfirmDialog";

const CommunicationDetails = () => {
    const [isSmtpRegister, setIsSmtpRegister] = useState("");
    const [state, setState] = useState<any>(true);
    const confirm = useConfirm();
    const [veryInitialData, setVeryInitialData] = useState<any>({
        "pass": "",
        "user": "",
        "host": "",
        "service": "",
        "port": "",
        "name": ""
    });
    const [initialData, setInitialData] = useState<any>({
        "pass": "",
        "user": "",
        "host": "",
        "service": "",
        "port": "",
        "name": ""
    });
    const [smtp, setSmtp] = useState<any>({
        "pass": "",
        "user": "",
        "host": "",
        "service": "",
        "port": "",
        "name": ""
    });
    const { data: uData } = useContext(UserProfileContext);

    const queryClient = useQueryClient();
    const [role, setRole] = useState(uData?.role?.name);


    const { data }: ResType = useQuery(["organization"], getSmtp, {

        onSuccess: (res: any) => {
            if (res.data === "" || res.data === null || res.data === undefined) {
                setState(true);
                setSmtp({});
                setInitialData({});
                setVeryInitialData({});
            } else {
                setState(false);
                const smtpData = {
                    pass: res?.data.auth["pass"],
                    user: res?.data.auth["user"],
                    host: res?.data["host"],
                    service: res?.data["service"],
                    port: res?.data["port"],
                    name: res?.data["name"],
                };
                setSmtp(smtpData);
                setInitialData(smtpData);
                setVeryInitialData(smtpData);
            }
        },
        onError: (err: any) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching organization details`);
            }
        },
        cacheTime: 0,
        enabled: true
    });


    const { mutateAsync, isLoading } = useMutation(updateSmtp, {
        onSuccess: () => {
            snack.success("The activation of your SMTP server configuration is scheduled for tomorrow !");
            queryClient.invalidateQueries("organization");
        },
        onError: (err: any) => {
            snack.error("Please Enter Valid Smtp Details");
            if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
                snack.error(err.response.data.message);
            } else if (!err?.error) {
                snack.error(`An error occurred while Updating Organization Profile`);
            }
        },
    });

    const onSubmit = async () => {
        const { ...data } = { ...uData.organisation, ...smtp };
        if (state) {
            try {
                confirm({
                    msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                    action: async () => {
                        await mutateAsync({ data: {} });
                    },
                });
            } catch (error: any) {
                snack.error(error);
            }
        } else {
            if (data.service === "Gmail") {
                if (data.name.trim().length === 0) {
                    snack.error("Please Enter Valid From Name")
                } else if (data.user.trim().length === 0) {
                    snack.error("Please Enter Valid Email")
                } else if (data.pass.trim().length === 0) {
                    snack.error("Please Enter Valid Password")
                } else {
                    try {
                        confirm({
                            msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                            action: async () => {
                                await mutateAsync({ data })
                            },
                        });
                    } catch (error) {
                        snack.error("");
                    }
                }
            } else if (data.service === "outlook") {
                if (data.name.trim().length === 0) {
                    snack.error("Please Enter Valid From Name")
                } else if (data.user.trim().length === 0) {
                    snack.error("Please Enter Valid Email")
                } else if (data.pass.trim().length === 0) {
                    snack.error("Please Enter Valid Password")
                } else {
                    try {
                        confirm({
                            msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                            action: async () => {
                                await mutateAsync({ data })
                            },
                        });
                    } catch (error) {
                        console.error("Error occurred during mutateAsync:", error);
                    }
                }
            }
        }
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'start', marginLeft: "50px" }}>
            {role === 'Admin' ? (
                <Grid container mr={5}>
                    <Grid item xs={12} mt={3} >
                        <FormControl>
                            <FormLabel sx={{ fontSize: '20px', fontWeight: 'bold' }}>Send Email From</FormLabel>
                            <RadioGroup
                                onChange={(event) => {
                                    setState(event.target.value === 'true')
                                    if (event.target.value === 'true') {
                                        setInitialData({})
                                    } else {
                                        setInitialData(veryInitialData);
                                    }
                                }}
                                value={state ? 'true' : 'false'}
                                row
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Vider Server"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="Custom SMTP"
                                />
                            </RadioGroup>
                        </FormControl>
                        {!state && <CustomSmtp smtp={smtp} setSmtp={setSmtp} />}
                        <Divider sx={{ mt: 5 }} />
                        <Typography mt={1} fontSize={"14px"}><span style={{ color: "red" }}>Disclaimer: </span>  Please be aware that if there is a shift between Servers or any changes made to the Organization's Customized SMTP (Simple Mail Transfer Protocol) Server Settings, the changes will take effect the following day instead of immediately. This change allows for additional processing time to ensure a smooth transition and minimize the risk of service disruptions.</Typography>
                    </Grid>
                    <BottomBar
                        data={initialData}
                        isLoading={isLoading}
                        state={smtp}
                        onCancel={() => setSmtp({
                            pass: data?.data.auth["pass"],
                            user: data?.data.auth["user"],
                            host: data?.data["host"],
                            service: data?.data["service"],
                            port: data?.data["port"],
                            name: data?.data["name"],
                        })}
                        onUpdate={onSubmit}
                    />
                </Grid>
            ) : (
                <Box
                    minHeight="80vh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginLeft={60}
                >
                    <Box
                        p={2}
                        borderRadius={2}
                        minWidth={300}
                        textAlign="center"
                        bgcolor="rgb(253, 237, 237)"
                    >
                        <BlockIcon
                            fontSize="large"
                            sx={{
                                color: "rgb(95, 33, 32)",
                            }}
                        />
                        <Typography variant="subtitle2" color="rgb(95, 33, 32)">
                            Permission Denied
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );


};
export default CommunicationDetails;