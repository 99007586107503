import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { downloadQuantumDocument, getClientDocuments } from "api/services/quantum";
import { snack } from "components/toast";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { getTitle } from "utils";
import { handleError } from "utils/handleError";
import { downloadPdf, downloadWord } from "assets";
import Table from "components/Table";
import { useConfirm } from "context/ConfirmDialog";
import { useState } from "react";
import SearchContainer from "components/SearchContainer";
import Actions from "views/taskview/documents/Action";
import moment from "moment";

const ClientWiseQuantumDocuments = () => {
    const params = useParams();
    const confirm = useConfirm();
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [search, setSearch] = useState("")
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
    const { data, isLoading }: ResType = useQuery(
        ["created-templates", {
            clientId: params.clientId,
            limit: pageCount,
            offset: page * pageCount,
            search
        }],
        getClientDocuments, {
        onSuccess: (result: any) => {
            setTotalCount(result?.data?.count)
        },
    }
    );

    const { mutate: downlaodMutate, isLoading: downloadLoading } = useMutation(downloadQuantumDocument, {
        onSuccess: (res) => {

            if (res?.data) {
                const pdfUrl = `https://docs.google.com/document/d/${res?.data?.documentId}/export?format=${res?.data?.downloadType}`;
                window.open(pdfUrl, '_blank');
            }
            snack.success("Document download successfully")
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const Columns: any = [
        { key: "task.taskNumber", title: "Task Id" },
        { key: "task.name", title: "Task Name" },
        { key: "templateName", title: "Document Name" },
        {
            key: "id", title: "Created On",
            render: (row) => {
                return (<Typography>{moment(row?.createdAt).format('DD/MM/YYYY')}</Typography>)
            }
        },
        {
            key: "documentStatus",
            title: "Status",
            render: (row) => {
                const backgroundColor =
                    row?.documentStatus === "completed"
                        ? "#F7964F" // Green color for completed status
                        : row?.documentStatus === "in_progress"
                            ? "#F2353C" // Yellow color for in progress status
                            : row?.documentStatus === "under_review"
                                ? "#149ECD" // Red color for under review status
                                : "#ffffff"; // Default color
                //"#F2353C", "#149ECD", "#F7964F"
                return (
                    <>

                        <Box
                            sx={{ display: "inline-flex" }}
                            bgcolor={backgroundColor}
                            px={2}
                            py="4px"
                            borderRadius={20}
                        >
                            <Typography variant="body2" color="white">
                                {getTitle(row?.documentStatus)}
                            </Typography>
                        </Box>
                    </>
                );
            }
        },
        {
            key: "id", title: "Download", render: (row) => {
                return (
                    <>
                        <Box>
                            <IconButton
                                size="medium"
                                onClick={() => handleDownloadClick(row, "pdf")}
                            >
                                <img style={{ width: 30, margin: 0 }} src={downloadPdf} alt="pdf" />
                            </IconButton>
                            <IconButton
                                size="medium"
                                onClick={() => handleDownloadClick(row, "docx")}
                            >
                                <img style={{ width: 30, margin: 0 }} src={downloadWord} alt="word" />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        },
        {
            key: "id", title: "Actions",
            render: (rowData: any) => {
                return (
                    <Box display={"flex"} gap={1}>
                        <Actions data={rowData} />
                    </Box>
                )
            }
        },
        { key: "user.fullName", title: "Created User" },
    ];

    const handleDownloadClick = (row: any, type: any) => {
        confirm({
            msg: `Do you want to download ${type} document?`,
            action: async () => {
                await downlaodMutate({
                    processInstanceId: row?.processInstanceId,
                    type: type
                })
            },
        });
    }
    return (

        <Box sx={{ m: 1 }} className="quantumclient-card-container">
            <Box display="flex" mr={3}>
                <SearchContainer
                    value={search}
                    debounced
                    minWidth={isSmallScreen ? "260px" : "400px"} 
                    onChange={setSearch}
                    placeHolder="Search by Document name"
                />
            </Box>
            <Table
                sx={{ mt: 2, height: "430px" }}
                loading={isLoading}
                data={data?.data?.result || []}
                columns={Columns}
                pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
            />
        </Box>

    )
}

export default ClientWiseQuantumDocuments