import { Box, IconButton, Typography } from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Visibility } from "@mui/icons-material";
import {
  getClientproceedingFyi,
} from "api/services/automation/income-tax";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import { resetState } from "redux/reducers/clientsSlice";

const ClientEproceedingFyi = (tab) => {
  useTitle("ATOM Pro | Clients");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(resetState());
    };
  }, []);
  const [checkAccess, setCheckAccess] = useState(true)
  const { data, isLoading, }: ResType = useQuery(
    [
      "client-proceedingfyi",
      params.incometaxid,
      {
        limit: pageCount,
        offset: page * pageCount,
      },
    ],
    getClientproceedingFyi,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
        setCheckAccess(res?.data?.accessDenied)
      },
    }
  );

  if (isLoading) return <Loader />
  return (
    <>

      {!checkAccess && (
        <AccessDenied />
      )}
      {checkAccess && (
        <Box p={2}>
          <Table
            sx={{ height: "480px" }}
            pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
            data={data?.data?.result || []}
            columns={columns}
            loading={isLoading}
          />
        </Box>
      )}

    </>
  );
};

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

const columns: Array<ColumnType> = [
  {
    key: "assessmentYear",
    title: "Assessment Year",
    render: (v) => (
      <Typography
      >
        {generateAssessmentYear(v?.assessmentYear)}
      </Typography>
    ),
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Name",
    render: (v) => (
      <Typography>
        {v.proceedingName}
      </Typography>
    ),
  },
  {
    key: "noticeName",
    title: (
      <Box display="flex" alignItems="center">
        <FormatListBulletedIcon style={{ width: "20px", marginRight: "5px" }} /> Sections
      </Box>
    ),
    render: (v) =>
      v?.noticeName ? (
        <Typography >
          {v?.noticeName}
        </Typography>
      ) : (
        "--"
      ),
  },
  {
    key: "",
    title: "Actions",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-information/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default ClientEproceedingFyi;
