import { http } from "api/http";

const getAutProfileDetails = ({ queryKey }: any) => {
  return http.get("/automation", { params: { ...queryKey[1] } });
};

const getIncomeTaxForms = ({ queryKey }: any) => {
  return http.get("/incometax/forms", { params: { ...queryKey[1] } });
};

const exportIncomeTaxForms = (data: any) => {
  return http.post("/incometax/export-forms", data);
};

const getFormData = ({ queryKey }: any) => {
  return http.get(`/incometax/form/${queryKey[1]}`);
};

const addAutClientCredentials = (data: any) => {
  return http.post("/incometax/credentials", data);
};

const updateAutClientCredentials = ({ id, data }: any) => {
  return http.put(`/incometax/credentials/${id}`, data);
};

const updateAppHierarchy = ({ id, data }: any) => {
  return http.put(`/approval-hierarchy/${id}`, data);
};

const getAutClientsCredentials = ({ queryKey }: any) => {
  return http.get("/incometax/credentials", { params: { ...queryKey[1] } });
};

const exportAutClientsCredentials = (data: any) => {
  return http.post("/incometax/export-credentials", data);
};

const getClientCredential = ({ queryKey }: any) => {
  return http.get(`incometax/credential/${queryKey[1]}`);
};

const getIncomeTaxProfile = ({ queryKey }: any) => {
  return http.get(`incometax/profile/${queryKey[1]}`);
};

const getIncomeTaxJurisdiction = ({ queryKey }: any) => {
  return http.get(`incometax/jurisdiction/${queryKey[1]}`);
};

const getAllClients = () => {
  return http.get("incometax/clients");
};

const getClientForm = ({ queryKey }: any) => {
  return http.get(`incometax/clientform/${queryKey[1]}`, { params: queryKey[2] });
};

const getClientReturn = ({ queryKey }: any) => {
  /////////////
  return http.get(`incometax/clientreturn/${queryKey[1]}`, { params: queryKey[2] });
};

const getIncomeTaxReturns = ({ queryKey }: any) => {
  return http.get("/incometax/returns", { params: { ...queryKey[1] } });
};

const exportIncomeTaxReturns = (data: any) => {
  return http.post("/incometax/incometax-returns", data);
};

const getReturnData = ({ queryKey }: any) => {
  return http.get(`/incometax/return/${queryKey[1]}`);
};

//DEMANDS
const getIncomeTaxDemands = ({ queryKey }: any) => {
  return http.get("/incometax/demands", { params: { ...queryKey[1] } });
};

const exportIncomeTaxDemands = (data: any) => {
  return http.post("/incometax/export-demands", data);
};

const getDemandsSections = () => {
  return http.get("incometax/sections-demands");
};

const getDemandData = ({ queryKey }: any) => {
  return http.get(`/incometax/demand/${queryKey[1]}`);
};

const getClientDemand = ({ queryKey }: any) => {
  return http.get(`incometax/clientdemand/${queryKey[1]}`, { params: queryKey[2] });
};

//E_PROCEEDINGS
const getIncomeTaxEproceedings = ({ queryKey }: any) => {
  return http.get("/incometax/action", { params: { ...queryKey[1] } });
};

const exportIncomeTaxEproceedings = (data: any) => {
  return http.post("/incometax/export-action", data);
};

const getIncomeTaxEproceedingsFyi = ({ queryKey }: any) => {
  return http.get("/incometax/information", { params: { ...queryKey[1] } });
};

const exportIncomeTaxEproceedingsFyi = (data: any) => {
  return http.post("/incometax/export-information", data);
};

const getEproceedingData = ({ queryKey }: any) => {
  return http.get(`/incometax/proceeding/${queryKey[1]}`);
};

const getEproceedingFyiData = ({ queryKey }: any) => {
  return http.get(`/incometax/information-pro/${queryKey[1]}`);
};

// Client Proceeding Find Your Action
const getClientproceedingFya = ({ queryKey }: any) => {
  return http.get(`/incometax/clientproceedingfya/${queryKey[1]}`, { params: queryKey[2] });
};

//SECTION DATA
const getFyiSections = () => {
  return http.get(`/incometax/sections-fyi`);
};

const getFyaSections = () => {
  return http.get(`/incometax/sections-fya`);
};

const getClientproceedingFyi = ({ queryKey }: any) => {
  return http.get(`/incometax/clientproceedingfyi/${queryKey[1]}`, {
    params: queryKey[2], // Passing query parameters
  });
};

const getClientEproceeding = ({ queryKey }: any) => {
  return http.get(`incometax/clientproceeding/${queryKey[1]}`);
};

const getIncomeTaxEproceedingNotice = ({ queryKey }: any) => {
  return http.get(`/incometax/proceeding/notice-action/${queryKey[1]}`);
};

const getIncomeTaxEproceedingNoticeFyi = ({ queryKey }: any) => {
  return http.get(`/incometax/proceeding/notice-information/${queryKey[1]}`);
};

const sendActivityData = (data: any) => {
  return http.post(`/incometax/activity`, data);
};

const getActivityLogData = ({ queryKey }: any) => {
  return http.get(`/incometax/activity/${queryKey[1]}`, { params: { ...queryKey[2] } });
};

const createRequest = ({ id, data }: any) => {
  return http.post(`/automation/auto-machine/${id}`, data);
};

const getClientAutoStatus = ({ queryKey }: any) => {
  return http.get(`/automation/clientAutoStatus/${queryKey[1]}`);
};

const bulkAutomationSync = (data: any) => {
  return http.post(`/automation/bulkSync`, data);
};

const checkNoOfSync = () => {
  return http.get(`/incometax/checkNoOfSync`);
};

const getIncomeTaxReports = ({ queryKey }: any) => {
  return http.get(`/automation/reports`, { params: { ...queryKey[1] } });
};

const exportIncomeTaxReports = (data: any) => {
  return http.post(`/automation/export-reports`, data);
};
const importCredentials = (data: any) => {
  return http.post(`/incometax/import`, data);
};

const getIncomeTaxNewUpdates = () => {
  return http.get(`/automation/new-updates`);
};

const getFyaNotice = ({ queryKey }: any) => {
  return http.get(`/automation/fya-notice`, { params: { ...queryKey[1] } });
};

const getEproceedingNotice = ({ queryKey }: any) => {
  return http.get(`/automation/eproceeding-notice`, { params: { ...queryKey[1] } });
};
const exportEproceedingNotice = (data: any) => {
  return http.post(`/automation/export-eproceeding-notice`,data);
};
const getNewUpdateItem = ({ queryKey }: any) => {
  return http.get(`/automation/update/${queryKey[1]}`);
};

const organizationScheduling = () => {
  return http.get(`/incometax/scheduling`);
};

const sendAtomProLimitRequest = () => {
  return http.post(`/automation/limitRequest`);
};

const sendAtomProRequest = (data: any) => {
  return http.post(`/automation/sendAtomProRequest`, data);
};

export {
  exportIncomeTaxReports,
  exportEproceedingNotice,
  exportIncomeTaxEproceedingsFyi,
  exportIncomeTaxEproceedings,
  exportIncomeTaxDemands,
  exportIncomeTaxForms,
  exportIncomeTaxReturns,
  exportAutClientsCredentials,
  getAutProfileDetails,
  getIncomeTaxForms,
  getFormData,
  addAutClientCredentials,
  getAutClientsCredentials,
  getClientCredential,
  getAllClients,
  getIncomeTaxProfile,
  getClientForm,
  getIncomeTaxReturns,
  getReturnData,
  getClientReturn,
  getIncomeTaxDemands,
  getDemandData,
  getClientDemand,
  getIncomeTaxEproceedings,
  getEproceedingData,
  getClientEproceeding,
  getIncomeTaxEproceedingNotice,
  getIncomeTaxEproceedingsFyi,
  getClientproceedingFya,
  getClientproceedingFyi,
  getEproceedingFyiData,
  getIncomeTaxEproceedingNoticeFyi,
  getFyiSections,
  getFyaSections,
  getDemandsSections,
  getIncomeTaxJurisdiction,
  updateAutClientCredentials,
  sendActivityData,
  getActivityLogData,
  createRequest,
  getClientAutoStatus,
  bulkAutomationSync,
  checkNoOfSync,
  getIncomeTaxReports,
  importCredentials,
  getIncomeTaxNewUpdates,
  getFyaNotice,
  getEproceedingNotice,
  getNewUpdateItem,
  organizationScheduling,
  sendAtomProLimitRequest,
  sendAtomProRequest,
};
