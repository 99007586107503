import { http } from "api/http";

const getServices = ({ queryKey }) => {
  return http.get("/services", { params: { ...queryKey[1] } });
};

const getServicesexport = (data) => {
  return http.post("/services/services-export", data);
};

const getDefaultServices = ({ queryKey }) => {
  return http.get("/services/default", { params: { ...queryKey[1] } });
};

const importServices = (data: any) => {
  return http.post("/services/import", data);
};

const getService = ({ queryKey }) => {
  return http.get(`/services/${queryKey[1]}`);
};

const createService = (data: any) => {
  return http.post("/services", data);
};

const updateService = ({ id, data }: any) => {
  return http.put(`/services/${id}`, data);
};

const deleteService = ({ id }: any) => {
  return http.delete(`/services/${id}`);
};

const cloneService = ({ id, data }: any) => {
  return http.post(`/services/${id}/clone`, data);
};

const updateAdminServices = () => {
  return http.post(`/services/update-admin-services`);
};

const getPreferedServices = ({ queryKey }) => {
  return http.get("/services/preferred-services", { params: { ...queryKey[1] } });
};

const bulkDelete = ({ ids }: any) => {
  return http.post(`/services/bulk-delete`, { ids });
};

const bulkClone = ({ ids }: any) => {
  return http.post(`/services/bulk-clone`, { ids });
};

const bulkUpdate = ({ ids, data }: any) => {
  return http.patch(`/services/bulk-update`, { ids, ...data });
};

const servicePreference = ({ id, action }: any) => {
  return http.post(`/services/${id}/preference`, { action });
};

//Service Dashboard
const getServiceAnalytics = ({ queryKey }) => {
  return http.get(`/stats/service-analytics`, { params: { ...queryKey[1] } });
};

//Service Dashboard Table
const getServiceDashboardDetails = ({ queryKey }) => {
  return http.get("/services/dashboard", { params: { ...queryKey[1] } });
}

const addServiceMileStone = ({ id, data }) => {
  console.log(id, data);
  return http.post(`/services/milestone/${id}`, data);
};
const updateServiceMileStone = ({ id, data }) => {
  console.log(id, data);
  return http.put(`/services/milestone/${id}`, data);
};

const deleteServiceMileStone = ({ id }) => {
  return http.delete(`/services/milestone/${id}`)
}

export {
  getServices,
  getServicesexport,
  getDefaultServices,
  importServices,
  createService,
  getService,
  updateService,
  deleteService,
  cloneService,
  updateAdminServices,
  getPreferedServices,
  servicePreference,
  bulkUpdate,
  bulkDelete,
  bulkClone,
  getServiceAnalytics,
  getServiceDashboardDetails,
  addServiceMileStone,
  updateServiceMileStone,
  deleteServiceMileStone
};
