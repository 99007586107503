export const getFinancialYears = () => {
  let fiscalYears: string[] = [];
  for (let i = 0; i < 1; i++) {
    const year = new Date().getFullYear() + i + 1;
    const fiscalYear = `${year}-${year + 1}`;
    fiscalYears.unshift(fiscalYear);
  } 
  for (let i = 0; i < 25; i++) {
    const year = new Date().getFullYear() - i;
    const fiscalYear = `${year}-${year + 1}`;
    fiscalYears.push(fiscalYear);
  }
  return fiscalYears;
};

export const getAutFinancialYears = () => {
  let fiscalYears: string[] = [];
  for (let i = 0; i < 1; i++) {
    const year = new Date().getFullYear() + i + 1;
    const fiscalYear = `${year}-${(year + 1).toString().slice(-2)}`;
    fiscalYears.unshift(fiscalYear);
  }
  for (let i = 0; i < 25; i++) {
    const year = new Date().getFullYear() - i;
    const fiscalYear = `${year}-${(year + 1).toString().slice(-2)}`;
    fiscalYears.push(fiscalYear);
  }
  return fiscalYears;
};

export const getServiceFinancialYears = () => {
  let fiscalYears: string[] = [];
  for (let i = 0; i < 1; i++) {
    const year = new Date().getFullYear() + i + 1;
    const fiscalYear = `${year}-${year + 1}`;
    fiscalYears.unshift(fiscalYear);
  }
  for (let i = 0; i < 5; i++) {
    const year = new Date().getFullYear() - i;
    const fiscalYear = `${year}-${year + 1}`;
    fiscalYears.push(fiscalYear);
  }
  return fiscalYears;
};
