export const formatToRupees = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        // Handle non-numeric or NaN input
        return 'Invalid input';
    }
    return number.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2, maximumFractionDigits: 2
    });
};

export const formatToRupeess = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        // Handle non-numeric or NaN input
        return '-.-';
    }
    return number.toLocaleString('en-IN', {
        currency: 'INR',
        minimumFractionDigits: 0, maximumFractionDigits: 0
    });
};


export const formatToRupeesWithOutDecemils = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        // Handle non-numeric or NaN input
        return 'Invalid input';
    }
    return number.toLocaleString('en-IN', {
        // style: 'currency',
        currency: 'INR',
    });
};




export const formattedAmount = (amount) => parseFloat(amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'INR' });

